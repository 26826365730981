.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html, body, body>div, .app {
  background-color: rgb(236, 243, 236);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: black;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.create-card {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.form-card {
  max-width: 100%;
  width: 100%;
  margin-top: 6%;
}

.view-pool-card {
  max-width: 100%;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 4% !important;
}

.text-header {
  margin-top: 7px;
  font-weight: bold;
}

.view-pool-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.view-pool-footer {
  font-weight: bold;
  text-align: center;
  background-color: #FFFFFF50;
}

.card-deck {
  padding-left: 2% !important;
  padding-right: 2% !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.card-header {
  font-weight: bold;
  font-size: large;
  min-width: 100%;
  max-width: 100%;
}

.hidden-card {
  opacity: 0;
  margin:0px !important;
}

.display-pool-card {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.display-pool-card:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 10px rgba(212, 108, 10, 0.562);
}

.card {
  border-radius: 30px !important;
}

.card-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.card-footer {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.info-message {
  margin-top: 30px;
  color: green;
  font-weight: bold;
  text-align: center;
}

.alert-message {
  margin-top: 20px;
  margin-bottom: 40px;
  color: orangered;
  font-weight: bold;
}

.warning-message {
  color: orange;
  font-weight: bold;
}

.g-pt-90 {
  padding-top: 17rem !important;
  color: rgb(10, 131, 103)
}

.auction-alert-message {
  color: orangered;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  font-size: large;
}

.auction-info-message {
  color: green;
  font-weight: bold;
  text-align: center;
  font-size: x-large;
  margin-top: 2%;
}

.faq p {
  width: 70%;
  margin: 0px auto;
}

.headertitle {
  font-size:20px !important;
  font-weight: bold;
}

h5 {
  margin-top:40px !important;
  font-weight: bold;
}

* { font-size: 98% !important; }

